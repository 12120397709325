import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import "./UserDashboard.scss";
import CustomSidebar from "../../components/sidebar/Sidebar";
import DebitTransaction from "../../components/table/DebitTransaction";
import CreditTransaction from "../../components/table/CreditTransaction";
import AllTransactionTable from "../../components/table/AllTransactionTable";
import Logout from "../../components/Button/Logout";
import HeaderNoButton from "../../components/header/HeaderNoButton";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { getToken } from "../../utils/utils";
import { jwtDecode } from "jwt-decode";
import TawkTo from "../../components/Tawkto/Tawkto";
import API_BASE_URL from "../../Config/config";
import WarningOverlay from "../../components/WarningOverlay/WarningOverlay";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"; // Import the LoadingSpinner

const UserDashboard = () => {
  const [user, setUser] = useState({});
  const [debitTransactions] = useState([]);
  const [creditTransactions] = useState([]);
  const [displayedBalance, setDisplayedBalance] = useState(0);
  const [fixedDepositStatus, setFixedDepositStatus] = useState({});
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user.id;

        // Fetch user data
        const userResponse = await axios.get(
          `${API_BASE_URL}/user/user/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUser(userResponse.data.user);
        animateBalance(0, userResponse.data.user.accountBalance, 3000);

        // If account type is fixed deposit, fetch the deposit status
        if (userResponse.data.user.accountType === "fixed deposit account") {
          const statusResponse = await axios.get(
            `${API_BASE_URL}/admin/fixed-deposit-status/${userId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setFixedDepositStatus(statusResponse.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading in case of error

        // Display a toast notification for the user
        toast.error("An error occurred while loading the dashboard data.");
      }
    };

    fetchUserData();
  }, []);

  const animateBalance = (start, end, duration) => {
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const currentBalance = Math.min(
        start + (progress / duration) * (end - start),
        end
      );
      setDisplayedBalance(currentBalance);

      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month}-${year}`;
  };

  if (loading) {
    return <LoadingSpinner />; // Display the loading spinner while loading
  }

  return (
    <React.Fragment>
      <WarningOverlay isSuspended={user.suspended} />
      <HeaderNoButton />
      <div className="d-flex">
        <Logout />
        <div className="sidebar-body">
          <CustomSidebar />
        </div>
        <div className="dashboard-body">
          <div className="account-balance">
            <Card
              key="custom-card"
              text="white"
              style={{ width: "25rem" }}
              className="mb-2 custom-card"
            >
              <Card.Header>Account Number: {user.accountNumber}</Card.Header>
              <Card.Body>
                <Card.Title className="amount">${" "}
                  {displayedBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Card.Title>
                <Card.Text>{user.accountType}</Card.Text>
              </Card.Body>
            </Card>

            {user.accountType === "fixed deposit account" && fixedDepositStatus.status === "locked" && (
              <div className="fixed-deposit-status">
                <p>This account is locked. Withdrawals will begin on {formatDate(fixedDepositStatus.date)}.</p>
              </div>
            )}

            <h3>Welcome</h3>
            <p className="username-text">{user.fullname}</p>
          </div>
          <AllTransactionTable/>
          <div className="debit-table">
            <h2>Debit Transactions</h2>
            <DebitTransaction transactions={debitTransactions} />
          </div>
          <div className="credit-table">
            <h2>Deposit Transactions</h2>
            <CreditTransaction transactions={creditTransactions} />
          </div>
        </div>
      </div>
      <TawkTo />
      <Footer />
    </React.Fragment>
  );
};

export default UserDashboard;

import React from 'react';
import './AboutUsSection.scss';
import AboutUsImage from '../../assets/images/aboutUs.jpg'

const AboutSection = () => {
  return (
    <div className="info-section">
      
      <div className="image-container">
        <img src={AboutUsImage} alt="Banking Services" />
      </div>
      <div className="text-container">
        <h2 className="heading">About Us</h2>
        <p className="text">
        Welcome to George Bank, where excellence meets innovation in
         the world of finance. Established with a vision to redefine 
         banking, George Bank has consistently set the standard for 
         financial services through our commitment to integrity, 
         customer-centricity, and cutting-edge technology.
        </p>
        <br/>
        <h3 className="heading2">Our Mission</h3>
        <p className="text">
        Our mission is to empower individuals, families, and businesses 
        by providing comprehensive, tailored financial solutions that 
        drive success and prosperity. We believe in fostering 
        relationships built on trust and transparency, ensuring that 
        our clients receive unparalleled service and support.
        </p>

        <br/>
        <h3 className="heading2">Our Values</h3>
        <p className="text">
        At the heart of George Bank are our core values:
        </p>
        <p className="text">
        <strong>Integrity</strong>: We uphold the highest ethical standards 
        in all our dealings, ensuring fairness and honesty.
        </p>
        <p className="text">
        <strong>Innovation</strong>: We leverage the latest technology to offer
         innovative solutions that make banking simpler and more efficient.
        </p>
        <p className="text">
        <strong>Customer-Centricity</strong>:  Our clients are at the center 
        of everything we do. We listen, understand, and adapt to their unique 
        needs and aspirations.
        </p>
        <p className="text">
        <strong>Excellence</strong>:  We strive for excellence in all aspects 
        of our operations, from customer service to financial performance.
        </p>

        <br/>
        <h3 className="heading2">Our Services</h3>
        <p className="text">
        George Bank offers a comprehensive range of banking services designed 
        to cater to the diverse needs of our clientele:
        </p>
        <p className="text">
        <strong>Personal Banking</strong>: Tailored solutions to help individuals 
        manage their finances, from savings and loans to wealth management.
        </p>
        <p className="text">
        <strong>Business Banking</strong>: Customized services that support businesses 
        of all sizes, including commercial loans, cash management, and financial advisory.
        </p>
        <p className="text">
        <strong>Digital Banking</strong>:  Innovative digital solutions that provide 
        convenient, secure, and efficient banking experiences.
        </p>

        <br/>
        <h3 className="heading2">Our Commitment</h3>
        <p className="text">
        We are committed to being a trusted partner in your financial journey. Our 
        team of experienced professionals is dedicated to delivering personalized 
        service and expert advice, ensuring you have the resources and support needed to 
        achieve your financial goals.
        </p>
        <br/>
        <p className="text">
        <strong>George Bank</strong> - Where Your Financial Future Begins.
        </p>
      </div> 
    </div>
  );
};

export default AboutSection;

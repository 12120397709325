import React, { useEffect } from 'react';

const Tawkto = () => {
  useEffect(() => {
    // Add the Tidio script to the page
    const script = document.createElement('script');
    // script.src = '//code.tidio.co/6ybukceqcaclaryreefzzvplaj0jpcpk.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script if the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* The Tidio widget will automatically appear when the script is loaded */}
    </div>
  );
};

export default Tawkto;

import React from 'react';
import './InfoSection.scss';
import InfoSectionImage from '../../assets/images/infoSection.jpg'

const InfoSection = () => {
  return (
    <div className="info-section">
      <div className="text-container">
        <h2 className="heading">Our Goal</h2>
        <p className="text">
        At George Bank, our goal is to revolutionize the banking
         experience by prioritizing the financial well-being and 
         satisfaction of our customers. We are dedicated to providing 
         innovative banking solutions that are tailored to meet the 
         diverse needs of individuals, families, and businesses alike. 
         Our mission is to empower our clients with the tools, knowledge, 
         and support they need to achieve their financial goals and secure 
         a prosperous future.
        </p>
        <br/>
        <p className="text">
        We strive to build lasting relationships based on trust, 
        transparency, and exceptional service. By leveraging 
        cutting-edge technology and a customer-centric approach, 
        we aim to make banking more accessible, convenient, and 
        rewarding for everyone. Our commitment to continuous 
        improvement ensures that we stay ahead of industry trends 
        and deliver the highest standards of excellence in all 
        that we do.
        </p>
        <br/>
        <p className="text">
        Join us at George Bank, where our goal is not just to meet 
        your banking needs, but to exceed your expectations every 
        step of the way. Together, we can create a brighter financial 
        future.
        </p>
      </div>
      <div className="image-container">
        <img src={InfoSectionImage} alt="Banking Services" />
      </div>
    </div>
  );
};

export default InfoSection;
